import { selectAssignment } from '@redux/experiments/selectors/index';
import { LiteStorefrontAssignment } from 'types/experiments';
import { State } from 'types/redux';

export const liteStorefrontAssignmentSelector = (state: State) => {
	const purchaseStatus = state.vendor.vendor?.purchaseStatus;

	if (purchaseStatus === 'PAID') return null;

	return selectAssignment(state, [
		'liteStorefront',
	]) as LiteStorefrontAssignment;
};

export const isLiteStorefrontSelector = (state: State) => {
	const assignment = liteStorefrontAssignmentSelector(state);
	return assignment === 'variant1';
};
