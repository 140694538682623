export function isRenameGardenVenuesFacetExperimentEnabled(
	seoFilter: string,
	stateCode: string | null,
): boolean {
	const experimentLocations = [
		'tx',
		'ny',
		'il',
		'ga',
		'mi',
		'va',
		'az',
		'ma',
		'mo',
		'wi',
		'mn',
		'al',
		'ky',
		'ok',
		'ut',
		'nv',
		'ks',
		'nm',
		'id',
		'hi',
		'me',
		'ri',
		'sd',
		'ak',
		'wy',
	];
	const experimentSeoFilter = 'Garden Wedding Venues';

	return (
		seoFilter === experimentSeoFilter &&
		stateCode != null &&
		experimentLocations.includes(stateCode.toLowerCase())
	);
}

export const seoTextVariantListingGarden =
	'Garden and Greenhouse Wedding Venues';
