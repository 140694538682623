/**
 * A truncate function to shorten a string to fit a provided
 */

interface GetTextWidth {
	(text: string, font: string): number;
	canvas?: HTMLCanvasElement;
}

/**
 * Calculates the rendered text's width in the provided font.
 *
 * @param {string} text - The string to be calculated on.
 * @param {string} font - The font to be calculated on: `${fontSize}px ${fontFamily}`.
 * @returns {number} - Width the the text rendered in the client in pixels.
 */
export const getTextWidth: GetTextWidth = (
	text: string,
	font: string,
): number => {
	if (typeof document === 'undefined') {
		return text.length * 6;
	} // hack for server side rendering
	const canvas =
		getTextWidth.canvas ||
		(getTextWidth.canvas = document.createElement('canvas'));
	const context = canvas.getContext('2d');
	context.font = font;
	const metrics = context.measureText(text);
	return metrics.width;
};

/**
 * Determines the font to be rendered in a given HTMLElement.
 *
 * @param {HTMLElement} element - The HTMLElement to be rendered.
 * @returns {string} - The font to be rendered: `${fontSize}px ${fontFamily}`.
 */
export const getElementFont = (element: HTMLElement): string => {
	const fontSize = window
		.getComputedStyle(element, null)
		.getPropertyValue('font-size');
	const fontFamily = window
		.getComputedStyle(element, null)
		.getPropertyValue('font-family');

	const fonts = fontFamily.split(',');
	const font = fonts[0];
	return `${fontSize} ${font}`;
};

/**
 * Determine the property value, pixels converted into a number
 *
 * @param {HTMLElement} element - The HTMLElement to be rendered.
 * @param {string} property - The property to be determined.
 * @returns {number|null} - The property value if it can be determined, or else null.
 */
const getPropertyValue = (
	element: HTMLElement,
	property: string,
): number | null => {
	const value = parseInt(
		window
			.getComputedStyle(element, null)
			.getPropertyValue(property)
			.slice(0, -2),
	);
	if (!value) {
		return null;
	}
	return value;
};

/**
 * Determines the element avialable width, if possible.
 *
 * @param {HTMLElement} element - The HTMLElement to be rendered.
 * @param {React.MutableRefObject<HTMLElement>} [ref] - A React MutableRefObject of the element that can be used to calculate size. This will be used over `element.width`.
 * @returns {number|null} - The available width to be rendered, or else null.
 */
export const getElementWidth = (
	element: HTMLElement,
	ref?: React.MutableRefObject<HTMLElement>,
): number | null => {
	const width = ref?.current?.offsetWidth || getPropertyValue(element, 'width');
	if (!width) {
		return null;
	}

	const paddingLeft = getPropertyValue(element, 'padding-left');
	const paddingRight = getPropertyValue(element, 'padding-left');

	return width - paddingLeft - paddingRight;
};

/**
 * Truncate a given string to fit a container and add ellipses.
 *
 * @param {string} text - The string to be truncated
 * @param {HTMLElement} element - The HTMLElement container to be rendered.
 * @param {number} [maximumLength] - The maximum length in pixels. If not provided, the width of the element will be used.
 * @param {React.MutableRefObject<HTMLElement>} [ref] - A React MutableRefObject of the element that can be used to calculate size. This will be used over `element.offsetWidth`.
 * @returns {string} - The shortedn text rendered in the client.
 */
export const truncate = (
	text: string,
	element: HTMLElement,
	maximumLength?: number,
	ref?: React.MutableRefObject<HTMLElement>,
) => {
	const font = getElementFont(element);
	const availableWidth = maximumLength || getElementWidth(element, ref);
	if (!availableWidth) {
		return text;
	}

	let trunatedText = text;
	let textWidth = getTextWidth(trunatedText, font);
	let hasEllipses = false;
	while (textWidth > availableWidth) {
		hasEllipses = true;
		trunatedText = trunatedText.slice(0, -1);
		textWidth = getTextWidth(`${trunatedText}...`, font);
	}
	return hasEllipses ? `${trunatedText}...` : trunatedText;
};
