export const DEI_FILTER_MAP = [
	{ id: '750f0833-d418-46ca-9551-558593eadfc9', name: 'Asian-owned' },
	{ id: '4fbcaa51-1beb-4efe-92aa-0d10faa832ba', name: 'Black-owned' },
	{
		id: 'bc6540d1-76c3-48ee-b67d-0523652f938a',
		name: 'Hispanic or Latinx-owned',
	},
	{ id: '98febab1-584c-4b7a-891c-795d9ea782fa', name: 'LGBTQ+-owned' },
	{ id: 'b8aade5c-c1ba-491f-940e-a4696447fddb', name: 'Native American-owned' },
	{
		id: '5e6ea1c3-f2d0-4d0c-913e-f7c725acb463',
		name: 'Pacific Islander-owned',
	},
	{ id: 'ccef61c6-db85-47d5-84c6-1bcf9fd979db', name: 'Veteran-owned' },
	{ id: 'd337171b-d5bd-43c8-bf8f-41fd4be63170', name: 'Woman-owned' },
];

export const DISTANCE_100_FILTER = '85b18cd6-48fc-4a99-b8e0-264754b139d4'; // distance(100 miles)
export const DISTANCE_FILTERS = [
	'34c04dae-42b8-41f7-b56c-bc19bc6eb00c', // within-5-miles
	'dee55673-e608-41f3-8821-8c7e652e3a72', // within-10-miles
	'c43d1381-140d-493b-8f04-819c832854d6', // within-25-miles
	'cbe54496-3bc7-4ec0-9125-73b045e1c2f4', // within-50-miles
];
