import { createSelector } from 'reselect';
import SpotlightDisplay from '../../pages/VendorsSearch/utils/SpotlightDisplay';

const selectSort = (state: Redux.State) => state.search.sort;
const selectAppliedFilters = (state: Redux.State) =>
	state.filters.appliedFilters;
const selectBOWFilter = (state: Redux.State) =>
	selectAppliedFilters(state)['best-of-weddings'];

export const selectIsBOWFilterApplied = createSelector(
	selectBOWFilter,
	(BOWFilter) =>
		BOWFilter &&
		undefined !== BOWFilter.find((filter) => filter.value === 'current-winner'),
);

export const selectShouldHideSpotlight = createSelector(
	selectAppliedFilters,
	selectSort,
	(appliedFilters, sort) => {
		const data = {
			search: {
				sort,
			},
			filters: {
				appliedFilters,
			},
		};
		const spotlightDisplay = new SpotlightDisplay(data);
		return spotlightDisplay.shouldHideSpotlight();
	},
);
