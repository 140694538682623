import flatten from '@utils/flatten';
import {
	capitalize,
	flatMap,
	initial,
	isEmpty,
	memoize,
	startCase,
} from 'lodash';
import queryString from 'query-string';
import { createSelector } from 'reselect';
import { waitForAnalytics } from '../../../utils';
import spotlightExperimentMarkets from './spotlightExperimentMarkets';

import { formatCityStateCode } from '../../../../utils/formatUrls';

import Constants from '../constants';

export const getCategoryFilterCount = (filters) =>
	filters.length ? `(${filters.length})` : '';

export const formOptionValue = ({ name, singular: { slug } }) =>
	slug || name.toLowerCase().split(' ').join('-');

export const buildFiltersForTracking = (params) => {
	const array = [];

	for (const [i, param] of Object.entries(params)) {
		if (!isEmpty(param)) {
			const obj = {};
			obj[i] = [...param.map((facetObj) => facetObj.value)];
			array.push(obj);
		}
	}

	const objects = Object.values(array);
	// objects = [{best-of-weddings: ['best-of-weddings-2018', 'hall-of-fame']}]
	const arrayOfMultiCategories = objects.map((obj) =>
		flatten([...Object.keys(obj), ...Object.values(obj)]),
	);
	// arrayOfMultiCategories = [['best-of-weddings', 'best-of-weddings-2018', 'hall-of-fame'], ['distance', 'within-10-miles']]
	const flattenedValues = objects.map((obj) =>
		flatten([...Object.values(obj)]),
	);

	const obj = {};
	arrayOfMultiCategories.forEach((a, i) => {
		obj[arrayOfMultiCategories[i][0]] = flatten([...flattenedValues[i]]);
	});

	return obj;
	// obj = {best-of-weddings: ['best-of-weddings-2018', 'hall-of-fame'], distance: ['within-10-miles']}
};

export const appendUrlParams = (
	newParams,
	isBlockListed = false,
	search = window.location.search,
) => {
	const params = {
		...queryString.parse(search),
		...newParams,
	};

	// do not include offset
	if (typeof params.offset !== 'undefined') {
		delete params.offset;
	}

	// do not include page 1
	if (params.page === 1) {
		delete params.page;
	}

	// for uncloaking, do not show 'sort=recommended' in the url #161258280
	// 'featured' for the block listed markets #163886755
	const hiddenSort = isBlockListed ? '' : 'recommended';
	if (newParams.sort === hiddenSort) {
		delete params.sort;
	}

	return queryString.stringify(params);
};

export const buildFiltersParams = (params) => {
	// params = { best-of-weddings: [{ value: 'hall-of-fame' }], settings: [{ value: 'ballroom' }, { value: 'barn' }], ... }
	const queries = Object.entries(params)
		// eslint-disable-next-line
		.filter(([_, val]) => val.length)
		.map(([key, val]) => {
			return key === 'outdoor_space' && val.length === 2
				? `${key}=uncovered-outdoor+covered-outdoor`
				: `${key}=${val.map((p) => p.value).join('+')}`;
		});

	return queries.length ? `?${queries.join('&')}` : '';
};

export const createFilterHref = (currentCategorySlug, option, location) => {
	const locationSlug = formatCityStateCode(location);
	return `/marketplace/${currentCategorySlug}-${locationSlug}/${formOptionValue(
		option,
	)}`;
};

export const getValuesFromCategoryFilters = (category, categoryFilters) => {
	return categoryFilters[category];
};

export const calculateFiltersCount = (filters) =>
	Object.keys(filters).reduce(
		(filterCount, currentFilter) => filterCount + filters[currentFilter].length,
		0,
	);

export const getAllFilterIds = (filters) =>
	flatten(Object.values(filters)).map(({ id }) => id);

export const getFilterKey = (filter) =>
	filter.slug || filter.name.toLowerCase();

export const getTotalPageCount = (state) => state.search.pagination.count;

export const getCurrentPage = (state) => state.search.pagination.page;

export const getBuzzSummary = (settings) => {
	let summary = {};

	if (settings.ads && settings.ads.adSummaries) {
		summary = settings.ads.adSummaries.find((o) => o.badgeCode === 'BUZZ');
	}

	return {
		badgeCode: summary?.badgeCode ?? '',
		badgeId: summary?.badgeId ?? 0,
		count: summary?.count ?? 0,
	};
};

export const shouldLazyLoadImage = (index, isMobile) =>
	isMobile ? index > 0 : index > 5;

export const searchFiltersToMap = (filters) =>
	filters.reduce((acc, filter) => {
		if (!filter) return acc;

		return {
			[getFilterKey(filter)]: filter.filterOptions.reduce(
				(ac, option) => ({
					[formOptionValue(option)]: option.id,
					...ac,
				}),
				{},
			),
			...acc,
		};
	}, {});

export const memoizedSearchFiltersToMap = memoize(
	searchFiltersToMap,
	(filters) => JSON.stringify(filters),
);

export const splitMultipleFilters = (filterObjs) =>
	flatMap(Object.entries(filterObjs), ([key, val]) => {
		const value = Array.isArray(val) ? val[0] : val;
		const filters = value.replace(
			/lgbtq -owned-business/,
			'lgbtq+-owned-business',
		);
		return filters.split(' ').map((filter) => [key, filter]);
	});

export const getLocationFromSlug = (slug) => {
	const locationSlugs = slug.split('-');
	const stateCode = locationSlugs[locationSlugs.length - 1].toUpperCase();
	const city = initial(locationSlugs).map(capitalize).join(' ');

	return {
		city,
		stateCode,
	};
};

export const getEmptyFilter = (categories) =>
	categories.reduce((acc, name) => ({ ...acc, [name]: [] }), {});

export const getValidFilters = (filters, filterMap) => {
	return filters.reduce((acc, [key, value]) => {
		const matchingFilter = filterMap[key]?.[value];

		if (!matchingFilter) {
			return acc;
		}

		acc.push(matchingFilter);
		return acc;
	}, []);
};

export const buildCategoryFilters = (
	filterArr,
	searchFilterMap,
	initialCategories,
) =>
	filterArr.reduce((acc, [key, value]) => {
		const matchingFilter = searchFilterMap[key]?.[value];

		if (!matchingFilter) {
			return acc;
		}

		const item = {
			id: matchingFilter,
			value,
		};

		return {
			...acc,
			[key]: [...acc[key], item],
		};
	}, initialCategories);

/**
 * Replace breadcrumb with selected & permitted filter option name when only one is applied.
 *
 * for allowedCode, return a facet,
 * when EXACTLY one facet is in applied filters,
 * and that applied facet is in the list of allowed facet keys'.
 * @param  {string} categoryCode
 * @param  {string[]} allowedCodes
 * @param  {object} appliedFilters
 * @param  {string[]} allowedFacetKeys
 * @return {string}
 */
export const getFacet = (
	categoryCode,
	allowedCodes,
	appliedFilters,
	allowedFacetKeys,
) => {
	if (!allowedCodes.includes(categoryCode)) {
		return null;
	}

	let facetKey;

	// eslint-disable-next-line
	for (const [key, val] of Object.entries(appliedFilters)) {
		// val is applied filter array
		if (val.length !== 0) {
			// once one applied filter array has more than one facet selected
			// or allowed facetKey has been found
			// or facet key is not allowed
			// break out
			if (
				val.length > 1 ||
				facetKey !== undefined ||
				!allowedFacetKeys.includes(key)
			) {
				return null;
			}

			facetKey = val[0].value;
		}
	}

	if (!facetKey) {
		return null;
	}

	return startCase(facetKey);
};

export const syntheticPageLoad = async ({
	city,
	marketCode: vendorMarketCode,
	member,
	vendorCategoryCode,
}) => {
	const memberId = member?.id || null;
	const analyticsLoaded = await waitForAnalytics();
	if (analyticsLoaded) {
		analytics.page({
			city,
			vendorMarketCode,
			userId_hitScope: memberId,
			userId_userScope: memberId,
			vendorCategoryCode,
			memberId,
		});
	}
};

// appliedFilters['best-of-weddings'].length of {} is 'false' which !== 0
export const BOWFiltersApplied = (appliedFilters) =>
	appliedFilters['best-of-weddings'] &&
	appliedFilters['best-of-weddings'].length !== 0;

export const isMarketBlockListed = (marketCode) =>
	Constants.BLOCKLISTED_MARKETS.find(
		(blockListMarket) => blockListMarket === marketCode,
	);

const selectAppliedFilters = (/** @type {Redux.State} */ state) =>
	state.filters.appliedFilters;
const selectSearchSort = (/** @type {Redux.State} */ state) =>
	state.search.sort;

export const showBuzzBadges = (appliedFilters, searchSort) =>
	!BOWFiltersApplied(appliedFilters) && searchSort === 'featured';

export const selectShowBuzzBadges = createSelector(
	selectAppliedFilters,
	selectSearchSort,
	showBuzzBadges,
);

/**
 * @param {string} marketCode
 * @param {Category.CategoryCode} categoryCode
 *
 * @return {boolean} whether or not we are in an eligible spotlight market
 */
export const isInSpotlightMarket = (marketCode, categoryCode) => {
	return !!spotlightExperimentMarkets.find(
		(e) => e.marketCode === marketCode && e.categoryCode === categoryCode,
	);
};
