export function isRenameHistoricVenuesFacetExperimentEnabled(
	seoFilter: string,
	stateCode: string | null,
): boolean {
	const experimentLocations = [
		'ca',
		'ny',
		'il',
		'ga',
		'mi',
		'va',
		'az',
		'ma',
		'wi',
		'mn',
		'al',
		'ky',
		'ok',
		'ut',
		'nv',
		'ks',
		'nm',
		'id',
		'hi',
		'me',
		'ri',
		'sd',
		'ak',
		'wy',
	];
	const experimentSeoFilter = 'Historic Wedding Venues';

	return (
		seoFilter === experimentSeoFilter &&
		stateCode != null &&
		experimentLocations.includes(stateCode.toLowerCase())
	);
}

export function getSeoTextVariantListing(): string {
	return 'Historic and Vintage Wedding Venues';
}
