export function isRenameEstateVenuesFacetExperimentEnabled(
	seoFilter: string,
	stateCode: string | null,
): boolean {
	const experimentLocations = [
		'tx',
		'ny',
		'il',
		'ga',
		'mi',
		'va',
		'az',
		'ma',
		'mo',
		'wi',
		'mn',
		'al',
		'ky',
		'ok',
		'ut',
		'nv',
		'ks',
		'nm',
		'id',
		'hi',
		'me',
		'ri',
		'sd',
		'ak',
		'wy',
		'oh',
	];
	const experimentSeoFilter = 'Estate Wedding Venues';

	return (
		seoFilter === experimentSeoFilter &&
		stateCode !== null &&
		experimentLocations.includes(stateCode.toLowerCase())
	);
}

export const seoTextVariantListingEstate = 'Estate and Mansion Wedding Venues';
