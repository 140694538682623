import { selectAssignment } from '@redux/experiments/selectors/index';
import { MobileMapViewAssignment } from 'types/experiments';
import { State } from 'types/redux';

export const mobileMapViewAssignmentSelector = (state: State) => {
	if (!state.viewport.isMobile || state.category.code !== 'REC') {
		return null;
	}

	return selectAssignment(state, ['mobileMapView']) as MobileMapViewAssignment;
};
