// The purpose of this class is to make the conditions under which we
// show/hide the ad spotlight easier to reason about
//
// Currently the spotlight should be hidden when the following conditions are met:
//
// * any "best of weddings" filter/sort is active
// * OR any distance filter is active
// * AND no other filters are active
// * always hidden if the sort is anything other than internal referrer sort ("featured")
//
// appliedFilters structure = {
//   'guest-capacity': [],
//   'distance': [],
//   'best-of-weddings': [{'hall-of-fame'}],
//   'settings': [{'backyard'}]
// }

import { SearchConstants } from '../../../../../settings';

const areNoOtherFiltersApplied = (appliedFilters) => {
	return Object.keys(appliedFilters)
		.filter((key) => key !== 'distance' && key !== 'best-of-weddings')
		.every((key) => appliedFilters[key].length === 0);
};

export default class SpotlightDisplay {
	constructor(state = {}) {
		this.filters = { ...state.filters.appliedFilters };
		this.bowFilter = this.isFilterApplied('best-of-weddings');
		this.distanceFilter = this.isFilterApplied('distance');
		this.noOtherFiltersApplied = areNoOtherFiltersApplied(this.filters);
		this.isInternalSort =
			state.search.sort === SearchConstants.INTERNAL_REFERRER_SORT;
	}

	distanceFilterApplied() {
		return this.distanceFilter;
	}

	bowFilterApplied() {
		return this.bowFilter;
	}

	shouldHideSpotlight() {
		return !this.shouldShowSpotlight();
	}

	shouldShowSpotlight() {
		return (
			this.isInternalSort &&
			!(this.distanceFilter && this.noOtherFiltersApplied)
		);
	}

	/* ----- "private" ----- */

	isFilterApplied(filterName) {
		return this.filters[filterName] && this.filters[filterName].length > 0;
	}
}
